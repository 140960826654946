/**
# See Vyoma LICENSE file https://gitlab.com/vyoma_systems/common/-/blob/main/LICENSE.vyoma for details
*/

import { useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// clsx is a utility for constructing className strings conditionally
import clsx from "clsx";

// @mui material components
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components

import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
//import NotificationItem from "examples/NotificationItem";

// Soft UI Dashboard React example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import PricingItemCard from "examples/PricingItemCard";
//import SidenavCard from "examples/Sidenav/SidenavCard";

// Custom styles for the Sidenav
import styles from "examples/Sidenav/styles/sidenav";

// Images
// import SoftUILogo from "assets/images/logo-ct.png";
import SoftUILogo from "assets/images/uptickpro-logo.png";
//import team2 from "assets/images/team-2.jpg";
//import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import beginner from "assets/images/beginner.png";
import enterprise from "assets/images/enterprise.png";
import pro from "assets/images/pro.png";

import { useAuth } from "../../auth-context/auth.context";
import AuthApi from "../../api/auth";

// Soft UI Dashboard React context
import { useSoftUIController } from "context";

function Sidenav({ routes, ...rest }) {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentSidenav } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [openInfoMenu, setOpenInfoMenu] = useState(false);
  const [showComponent, setShowComponent] = useState(false);
  const [showComponent2, setShowComponent2] = useState(false);

  const classes = styles({ miniSidenav, transparentSidenav });
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];

  const closeSizenav = () => dispatch({ type: "MINI_SIDENAV", value: true });
  const { user } = useAuth();

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      dispatch({
        type: "MINI_SIDENAV",
        value: window.innerWidth < 1200,
      });
    }

    /**
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleInfoOpenMenu = (event) => {
    //    console.log(event.target);
    //    console.log(event.currentTarget);
    setOpenInfoMenu(event.currentTarget);
  };
  const handleInfoCloseMenu = () => setOpenInfoMenu(false);

  const renderInfoMenu = () => (
    <Menu
      anchorEl={openInfoMenu}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(openInfoMenu)}
      onClose={handleInfoCloseMenu}
      style={{ marginTop: "1rem" }}
    >
      <SuiBox>
        <SuiTypography variant="caption" textColor="primary">
          10% discount on yearly subscription
        </SuiTypography>
      </SuiBox>
    </Menu>
  );

  const handleAlert = () => {
    setShowComponent(false);
  };
  const handleRequest = async () => {
    try {
      setShowComponent(true);
      await AuthApi.pricingUpgrade(user);
    } catch (err) {
      console.log(err);
    }
  };

  const handleAlert2 = () => {
    setShowComponent2(false);
  };
  const handleRequest2 = () => {
    let url = "https://vyomasystems.com/#getstarted";
    window.open(url, "_blank");
    setShowComponent2(true);
  };
  const points = ["1 user", "1 RISC-V Simulation IDE", "CoCoTb Env", "Waveform viewer"];

  const addons = ["Software BringUp IDE", "Custom IDE"];

  const points2 = [
    "2-5 Users",
    "2 RISC-V Simulation IDEs per user",
    "RISC-V and Peripheral VIPs",
    "CoCoTb Env",
    "Waveform & Coverage viewers",
  ];

  const addons2 = ["On-Perm support", "Software BringUp IDE", "Time based Emulation IDE"];

  const points3 = [
    "Custom Users",
    "Custom IDEs",
    "RISC-V and Peripheral VIPs",
    "CoCoTb Env",
    "Waveform & Coverage viewers",
    "DevOps support",
    "AI assisted testing and debug",
  ];

  const addons3 = ["On-Perm support", "Software BringUp IDE", "Fully supported Emulation IDE"];
  const pricingMenu = () => (
    <Menu
      anchorEl={openMenu}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      style={{ marginBottom: "1rem" }}
      sx={{
        "& .MuiPaper-root": {
          maxHeight: "none", // Allow full height
          overflow: "visible", // Prevent scrollbars
        },
      }}
    >
      <Grid container spacing={1} padding={1} direction="row">
        <Grid item xs={11} lg={4}>
          <PricingItemCard
            image={<img src={beginner} alt="person" />}
            heading="Beginner"
            title="Individual developers"
            points={points}
            addons={addons}
            onRequest={handleRequest}
            buttonName="Get Upgraded"
            price="160 USD"
            buttonColor="primary"
            onInfoRequest={handleInfoOpenMenu}
            menuInfo={renderInfoMenu}
          />
          {showComponent && (
            <SweetAlert
              title={
                <span style={{ fontSize: "16px" }}>
                  Thanks for your Vyoma&apos;s UpTickPro Subscription request. Please look for
                  E-mail notifications within 24 hours for getting access.
                </span>
              }
              onCancel={handleAlert}
              onConfirm={handleAlert}
              confirmBtnCssClass={"px-5"}
            />
          )}
        </Grid>
        <Grid item xs={11} lg={4}>
          <PricingItemCard
            image={<img src={enterprise} alt="person" />}
            heading="Pro"
            title="Small teams"
            points={points2}
            addons={addons2}
            onRequest={handleRequest2}
            buttonName="Get Started"
            buttonColor="info"
          />
          {showComponent2 && (
            <SweetAlert
              title={
                <span style={{ fontSize: "16px" }}>
                  Provide your details in the contact form, and we will contact you at the earliest.
                </span>
              }
              onCancel={handleAlert2}
              onConfirm={handleAlert2}
              confirmBtnCssClass={"px-1"}
            />
          )}
        </Grid>
        <Grid item xs={11} lg={4}>
          <PricingItemCard
            image={<img src={pro} alt="person" />}
            heading="Enterprise"
            title="Large teams"
            points={points3}
            addons={addons3}
            onRequest={handleRequest2}
            buttonName="Get Started"
            buttonColor="info"
          />
        </Grid>
      </Grid>
    </Menu>
  );

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, route, href }) => {
    let returnValue;

    if (type === "collapse") {
      returnValue = href ? (
        <Link
          href={href}
          key={key}
          target="_blank"
          rel="noreferrer"
          className={classes.sidenav_navlink}
        >
          <SidenavCollapse
            name={name}
            icon={icon}
            active={key === collapseName}
            noCollapse={noCollapse}
          />
        </Link>
      ) : (
        <NavLink to={route} key={key} className={classes.sidenav_navlink}>
          <SidenavCollapse
            name={name}
            icon={icon}
            active={key === collapseName}
            noCollapse={noCollapse}
          />
        </NavLink>
      );
    } else if (type === "title") {
      returnValue = (
        <SuiTypography
          key={key}
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          customClass={classes.sidenav_title}
        >
          {title}
        </SuiTypography>
      );
    } else if (type === "divider") {
      returnValue = <Divider key={key} />;
    }

    return returnValue;
  });

  return (
    <Drawer
      {...rest}
      variant="permanent"
      classes={{
        paper: clsx(classes.sidenav, {
          [classes.sidenav_open]: !miniSidenav,
          [classes.sidenav_close]: miniSidenav,
        }),
      }}
    >
      <SuiBox customClass={classes.sidenav_header}>
        <SuiBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          customClass="cursor-pointer"
          onClick={closeSizenav}
        >
          <SuiTypography variant="h6" textColor="secondary">
            <Icon className="font-bold">close</Icon>
          </SuiTypography>
        </SuiBox>
        <NavLink to="/">
          <SuiBox
            component="img"
            src={SoftUILogo}
            alt="Soft UI Logo"
            customClass={classes.sidenav_logo}
          />
        </NavLink>
        <SuiBox customClass={classes.sidenav_logoLabel}>
          <SuiTypography component="h6" variant="button" fontWeight="medium">
            UpTickPro 1.0.0
          </SuiTypography>
        </SuiBox>
      </SuiBox>
      <Divider />
      <List>{renderRoutes}</List>
      {pricingMenu()}
      <SuiBox customClass={classes.sidenav_footer}>
        <SuiBox mt={2}>
          <SuiButton
            component="label"
            variant="gradient"
            buttonColor="info"
            onClick={handleOpenMenu}
            fullWidth
          >
            Upgrade
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </Drawer>
  );
}

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
