/**
# See Vyoma LICENSE file https://gitlab.com/vyoma_systems/common/-/blob/main/LICENSE.vyoma for details
*/

import { useState, useEffect } from "react";

// react-router-dom components
import { Link } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";

// @mui material components
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
//import Icon from "@mui/material/Icon";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
//import vyomaLogo from "assets/images/logos/vyoma_logo_full.png";
import image_bg from "assets/images/logos/uptickpro.jpg";
import logo_full from "assets/images/logo_full.png";
//import beginner from "assets/images/beginner.png";
import { useAuth } from "../../../auth-context/auth.context";
import AuthApi from "../../../api/auth";

// Soft UI Dashboard React icons
import Shop from "examples/Icons/Shop";
//import Office from "examples/Icons/Office";
//import Settings from "examples/Icons/Settings";
import Document from "examples/Icons/Document";
import SpaceShip from "examples/Icons/SpaceShip";
import CustomerSupport from "examples/Icons/CustomerSupport";
import CreditCard from "examples/Icons/CreditCard";

//import Cube from "examples/Icons/Cube";

// Soft UI Dashboard React layouts
import Dashboard from "layouts/dashboard";
//import Tables from "layouts/tables";
//import Billing from "layouts/billing";
//import VirtualReality from "layouts/virtual-reality";
//import IclassArtifacts from "layouts/artifacts/iclass";
//import CclassArtifacts from "layouts/artifacts/cclass";
//import Project from "layouts/project";
//import RTL from "layouts/rtl";
import Profile from "layouts/profile";
import Analysis from "layouts/analysis";
//import SignInComp from "layouts/authentication/sign-in";
// import SignUp from "layouts/authentication/sign-up";
import SignOut from "layouts/authentication/sign-out";

import { useHistory, useLocation } from "react-router-dom";
function SignIn() {
  const history = useHistory();
  const location = useLocation();
  const { setUser } = useAuth();
  const { user } = useAuth();
  const { setRoutes } = useAuth();

  const [agreement, setAgremment] = useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [visibility, setVisibility] = useState(false);
  const [error, setError] = useState(undefined);
  //  const [loginProgress, setLoginProgress] = useState(false);
  const [buttonText, setButtonText] = useState("Sign in");
  const handleSetAgremment = () => {
    setAgremment(!agreement);
    setError(undefined);
  };

  const visibilityChange = () => {
    //alert("changed the visibility");
    setVisibility(!visibility);
  };

  const login = async (event) => {
    if (event) {
      event.preventDefault();
    }
    if (userName === "") {
      return setError("You must enter your username.");
    }
    if (password === "") {
      return setError("You must enter your password");
    }

    if (agreement === false) {
      return setError("Please confirm you’ve read our privacy policy & terms and conditions");
    }

    setButtonText("Signing in");
    try {
      let response = await AuthApi.Login({
        userName,
        password,
      });
      if (response.data && response.data.success === false) {
        return setError(response.data.msg);
      }
      return setProfile(response);
    } catch (err) {
      console.log(err);
      setButtonText("Sign in");
      if (err.response) {
        return setError(err.response.data.msg);
      }
      return setError("There has been an error.");
    }
  };

  const setProfile = async (response) => {
    let user = { ...response.data.user };
    user.token = response.data.token;
    //    setLoginProgress(true);
    user = JSON.stringify(user);
    setUser(user);
    localStorage.setItem("user", user);
    user = typeof user === "string" ? JSON.parse(user) : user;
    let prem_response = await AuthApi.PermList(user);
    if (prem_response.data.admin_access === true) {
      setRoutes([
        {
          type: "collapse",
          name: "Projects",
          key: "projects",
          route: "/projects",
          icon: <Shop size="12px" />,
          component: Dashboard,
          noCollapse: true,
          protected: true,
        },
        { type: "title", title: "Account Pages", key: "account-pages" },
        {
          type: "collapse",
          name: "Profile",
          key: "profile",
          route: "/profile",
          icon: <CustomerSupport size="12px" />,
          component: Profile,
          noCollapse: true,
          protected: true,
        },
        {
          type: "collapse",
          name: "Analysis",
          key: "analysis",
          route: "/analysis",
          icon: <CreditCard size="12px" />,
          component: Analysis,
          noCollapse: true,
          protected: true,
        },
        {
          type: "none",
          name: "Sign In",
          key: "sign-in",
          route: "/authentication/sign-in",
          icon: <Document size="12px" />,
          component: SignIn,
          noCollapse: true,
        },
        // {
        //   type: "none",
        //   name: "Sign Up",
        //   key: "sign-up",
        //   route: "/authentication/sign-up",
        //   icon: <SpaceShip size="12px" />,
        //   component: SignUp,
        //   noCollapse: true,
        // },
        {
          type: "collapse",
          name: "Logout",
          key: "sign-out",
          route: "/authentication/sign-out",
          icon: <SpaceShip size="12px" />,
          component: SignOut,
          noCollapse: true,
        },
      ]);
    }

    //    let permissions = await AuthApi.Permissions(user);
    //    console.log("permissio list" + permissions);
    //    const permList = permissions.data.permission_list;
    //    setRoutes([
    //      {
    //        type: "collapse",
    //        name: "Dashboard",
    //        key: "dashboard",
    //        route: "/dashboard",
    //        icon: <Shop size="12px" />,
    //        component: Dashboard,
    //        noCollapse: true,
    //        protected: true,
    //      },
    //    ]);
    //    for (let i = 0; i < permList.length; i++) {
    //      if (permList[i] == "cclass_build") {
    //        setRoutes((prev) => [
    //          ...prev,
    //          {
    //            type: "collapse",
    //            name: "Cclass",
    //            key: "cclass",
    //            route: "/cclass",
    //            icon: <Document size="12px" />,
    //            component: CclassArtifacts,
    //            noCollapse: true,
    //            protected: true,
    //          },
    //        ]);
    //      } else if (permList[i] == "iclass_build") {
    //        setRoutes((prev) => [
    //          ...prev,
    //          {
    //            type: "collapse",
    //            name: "Iclass",
    //            key: "iclass",
    //            route: "/iclass",
    //            icon: <Office size="12px" />,
    //            component: IclassArtifacts,
    //            noCollapse: true,
    //            protected: true,
    //          },
    //        ]);
    //      } else if (permList[i] == "vscode") {
    //        setRoutes((prev) => [
    //          ...prev,
    //          {
    //            type: "collapse",
    //            name: "Dev_Component",
    //            key: "Dev_Component",
    //            route: "/Dev_Component",
    //            icon: <Cube size="12px" />,
    //            component: VirtualReality,
    //            noCollapse: true,
    //            protected: true,
    //          },
    //        ]);
    //      }
    //    }
    //    setRoutes((prev) => [
    //      ...prev,
    //      {
    //        type: "collapse",
    //        name: "Project",
    //        key: "Project",
    //        route: "/Project",
    //        icon: <Cube size="12px" />,
    //        component: Project,
    //        noCollapse: true,
    //        protected: true,
    //      },
    //      { type: "title", title: "Account Pages", key: "account-pages" },
    //      {
    //        type: "collapse",
    //        name: "Profile",
    //        key: "profile",
    //        route: "/profile",
    //        icon: <CustomerSupport size="12px" />,
    //        component: Profile,
    //        noCollapse: true,
    //        protected: true,
    //      },
    //      {
    //        type: "none",
    //        name: "Sign In",
    //        key: "sign-in",
    //        route: "/authentication/sign-in",
    //        icon: <Document size="12px" />,
    //        component: SignIn,
    //        noCollapse: true,
    //      },
    //      // {
    //      //   type: "none",
    //      //   name: "Sign Up",
    //      //   key: "sign-up",
    //      //   route: "/authentication/sign-up",
    //      //   icon: <SpaceShip size="12px" />,
    //      //   component: SignUp,
    //      //   noCollapse: true,
    //      // },
    //      {
    //        type: "collapse",
    //        name: "Logout",
    //        key: "sign-out",
    //        route: "/authentication/sign-out",
    //        icon: <SpaceShip size="12px" />,
    //        component: SignOut,
    //        noCollapse: true,
    //      },
    //    ]);
    //    setLoginProgress(true);
    return history.push("/projects");
  };
  //  if (location.state && location.state.hasOwnProperty('key')) {
  //    const passedData = location.state.key;
  //    console.log(passedData);
  //  }
  const hasKey = "key" in (location.state || {});
  //console.log(hasKey);
  useEffect(async () => {
    try {
      let prem_response = await AuthApi.PermList(user);
      if (prem_response.data.admin_access === true) {
        setRoutes([
          {
            type: "collapse",
            name: "Projects",
            key: "projects",
            route: "/projects",
            icon: <Shop size="12px" />,
            component: Dashboard,
            noCollapse: true,
            protected: true,
          },
          { type: "title", title: "Account Pages", key: "account-pages" },
          {
            type: "collapse",
            name: "Profile",
            key: "profile",
            route: "/profile",
            icon: <CustomerSupport size="12px" />,
            component: Profile,
            noCollapse: true,
            protected: true,
          },
          {
            type: "collapse",
            name: "Analysis",
            key: "analysis",
            route: "/analysis",
            icon: <CreditCard size="12px" />,
            component: Analysis,
            noCollapse: true,
            protected: true,
          },
          {
            type: "none",
            name: "Sign In",
            key: "sign-in",
            route: "/authentication/sign-in",
            icon: <Document size="12px" />,
            component: SignIn,
            noCollapse: true,
          },
          {
            type: "collapse",
            name: "Logout",
            key: "sign-out",
            route: "/authentication/sign-out",
            icon: <SpaceShip size="12px" />,
            component: SignOut,
            noCollapse: true,
          },
        ]);
      }
      return history.push("/projects");
    } catch (error) {
      console.log("Error is :" + error);
      if (typeof error.response !== "undefined" && error.response.status == 403) {
        setUser(null);
        localStorage.removeItem("user");
      }
    }
  }, []);

  return (
    <CoverLayout
      title="Welcome to Vyoma's UpTickPro"
      description={`${
        user && user.token
          ? ""
          : hasKey
          ? location.state.key
          : "Enter your username and password to sign in"
      }`}
      image={logo_full}
      bg_image={image_bg}
      points={`Be RISC-V Ready with Vyoma's UpTickPro`}
      points2={`Accelerate your RISC-V product to market using ready-to-deploy`}
      points3={`RISC-V Verification, Emulation, Software ecosystem solutions`}
    >
      <SuiBox component="form" role="form">
        <SuiBox mb={2}>
          <SuiBox mb={1} ml={0.5}>
            <SuiTypography component="label" variant="caption" fontWeight="bold">
              Username
            </SuiTypography>
          </SuiBox>
          <SuiInput
            defaultValue={userName}
            onChange={(event) => {
              setUserName(event.target.value);
              setError(undefined);
            }}
            type="userName"
            placeholder="Username"
          />
        </SuiBox>
        <SuiBox mb={2}>
          <SuiBox mb={1} ml={0.5}>
            <SuiTypography component="label" variant="caption" fontWeight="bold">
              Password
            </SuiTypography>
          </SuiBox>
          <SuiInput
            defaultValue={password}
            onChange={(event) => {
              setPassword(event.target.value);
              setError(undefined);
            }}
            withIcon={{
              icon: visibility ? <VisibilityOff /> : <Visibility />,
              direction: "right",
            }}
            type={visibility ? "text" : "password"}
            placeholder="Password"
            handleIconClick={visibilityChange}
          />
          <SuiTypography variant="button" textColor="text" fontWeight="regular" textAlign="right">
            <SuiTypography
              component={Link}
              to="/authentication/forgot-pwd"
              variant="button"
              textColor="info"
              fontWeight="medium"
              textGradient
            >
              Forgot password?{" "}
            </SuiTypography>
          </SuiTypography>
        </SuiBox>
        <SuiBox display="flex" alignItems="center">
          <Checkbox checked={agreement} onChange={handleSetAgremment} />
          <SuiTypography
            variant="button"
            fontWeight="regular"
            onClick={handleSetAgremment}
            sx={{ cursor: "poiner", userSelect: "none" }}
          >
            I agree to&nbsp;
          </SuiTypography>
          <SuiTypography
            component="a"
            href="https://vyomasystems.com/privacy.html"
            variant="button"
            fontWeight="bold"
            textGradient
            target="_blank"
          >
            Privacy Policy,&nbsp;
          </SuiTypography>
          <SuiTypography
            component="a"
            href="https://vyomasystems.com/terms.html"
            variant="button"
            fontWeight="bold"
            textGradient
            target="_blank"
          >
            Terms & Conditions
          </SuiTypography>
        </SuiBox>
        <SuiBox mb={2} textAlign="center">
          <h6
            style={{
              fontSize: ".8em",
              color: "red",
              textAlign: "center",
              fontWeight: 400,
              transition: ".2s all",
            }}
          >
            {error}
          </h6>
        </SuiBox>

        <SuiBox mt={4} mb={1}>
          <SuiButton variant="gradient" buttonColor="info" fullWidth onClick={login}>
            {buttonText}
          </SuiButton>
        </SuiBox>
        <SuiBox mt={3} textAlign="center">
          <SuiTypography variant="button" textColor="text" fontWeight="regular">
            Don&apos;t have an account?{" "}
            <SuiTypography
              component={Link}
              to="/authentication/sign-up"
              variant="button"
              textColor="info"
              fontWeight="medium"
              textGradient
            >
              Sign up
            </SuiTypography>
          </SuiTypography>
        </SuiBox>
      </SuiBox>
    </CoverLayout>
  );
}

export default SignIn;
