/**
# See Vyoma LICENSE file https://gitlab.com/vyoma_systems/common/-/blob/main/LICENSE.vyoma for details
*/

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
//import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import { Visibility, VisibilityOff } from "@mui/icons-material";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

// Authentication layout components
//import BasicLayout from "layouts/authentication/components/BasicLayout";
import CoverLayout from "layouts/authentication/components/CoverLayout";
//import Socials from "layouts/authentication/components/Socials";
//import Separator from "layouts/authentication/components/Separator";

// Images
//import curved6 from "assets/images/curved-images/curved14.jpg";
import logo_full from "assets/images/logo_full.png";
import image_bg from "assets/images/logos/uptickpro.jpg";

import AuthApi from "../../../api/auth";
//import { useHistory } from "react-router-dom";

function SignUp() {
  //  const history = useHistory();
  const [agreement, setAgremment] = useState(false);
  const [userName, setName] = useState("");
  const [firstName, setfirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cnfrmpassword, setcnfrmPassword] = useState("");
  const [org_code, setOrgcode] = useState("");
  const [buttonText, setButtonText] = useState("Sign up");
  const [error, setError] = useState({});
  const [visibility, setVisibility] = useState(false);
  const [confrmVisibility, setConfirmVisibility] = useState(false);

  const handleSetAgremment = () => setAgremment(!agreement);

  const visibilityChange = () => {
    //alert("changed the visibility");
    setVisibility(!visibility);
  };

  const visibilityConfrmChange = () => {
    //alert("changed the visibility");
    setConfirmVisibility(!confrmVisibility);
  };

  const register = async (event) => {
    if (event) {
      event.preventDefault();
    }
    if (userName === "") {
      return setError({ message: "You must enter your username.", color: "red" });
    } else {
      var usernamePattern = /^[a-z]{5,}$/;
      let isvalid = usernamePattern.test(userName);
      if (!isvalid) {
        return setError({
          message:
            "Username must contain only lowercase letters and be at least 5 characters long. No special characters are allowed",
          color: "red",
        });
      }
    }
    if (firstName === "") {
      return setError({ message: "You must enter your First name.", color: "red" });
    }
    if (lastName === "") {
      return setError({ message: "You must enter your Last name.", color: "red" });
    }
    if (company === "") {
      return setError({ message: "You must enter your Institute/Company.", color: "red" });
    }
    if (country === "") {
      return setError({ message: "You must enter your country.", color: "red" });
    }
    if (email === "") {
      return setError({ message: "You must enter your email.", color: "red" });
    } else {
      var emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      let isvalid = emailPattern.test(email);
      if (!isvalid) {
        return setError({ message: "Enter a valid email address.", color: "red" });
      }
    }
    if (password === "") {
      return setError({ message: "You must enter password.", color: "red" });
    }
    if (cnfrmpassword === "") {
      return setError({ message: "You must enter confirm password.", color: "red" });
    }
    if (cnfrmpassword !== password) {
      return setError({ message: "Password and Confirm password must match.", color: "red" });
    }

    if (agreement === false) {
      return setError({
        message: "Please confirm you’ve read our privacy policy & terms and conditions",
        color: "red",
      });
    }
    try {
      setButtonText("Signing up");
      var response;
      if (org_code === "") {
        response = await AuthApi.Register({
          username: userName,
          firstname: firstName,
          lastname: lastName,
          company,
          country,
          email,
          password,
        });
      } else {
        response = await AuthApi.Register({
          username: userName,
          firstname: firstName,
          lastname: lastName,
          company,
          country,
          email,
          password,
          org_code,
        });
      }
      if (response.data && response.data.success === false) {
        setButtonText("Sign up");
        return setError({ message: response.data.msg, color: "red" });
      }
      if (response.data && response.data.success === true) {
        setButtonText("Sign up");
        return setError({ message: response.data.msg, color: "green" });
      }
    } catch (err) {
      console.log(err);
      setButtonText("Sign up");
      if (err.response) {
        return setError({ message: err.response.data.msg, color: "red" });
      }
      return setError({ message: "There has been an error.", color: "red" });
    }
  };

  return (
    <CoverLayout
      title="Welcome to Vyoma's UpTickPro!"
      description="Sign up to create new account in uptickpro for free."
      image={logo_full}
      bg_image={image_bg}
      points={`Be RISC-V Ready with Vyoma's UpTickPro`}
      points2={`Accelerate your RISC-V product to market using ready-to-deploy`}
      points3={`RISC-V Verification, Emulation, Software ecosystem solutions`}
    >
      <SuiBox mb={2}>
        <SuiTypography variant="button" fontWeight="medium" customClass="w-max">
          Username
        </SuiTypography>
        <SuiInput
          onChange={(event) => {
            setName(event.target.value);
            setError({});
          }}
        />
      </SuiBox>
      <SuiBox>
        <Grid container spacing={1}>
          <Grid item xs={6} md={6} lg={6}>
            <SuiTypography variant="button" fontWeight="medium" customClass="w-max">
              First Name
            </SuiTypography>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <SuiTypography variant="button" fontWeight="medium" customClass="w-max">
              Last Name
            </SuiTypography>
          </Grid>
        </Grid>
      </SuiBox>
      <SuiBox mb={2}>
        <Grid container spacing={1}>
          <Grid item xs={6} md={6} lg={6}>
            <SuiInput
              onChange={(event) => {
                setfirstName(event.target.value);
                setError({});
              }}
            />
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <SuiInput
              onChange={(event) => {
                setLastName(event.target.value);
                setError({});
              }}
            />
          </Grid>
        </Grid>
      </SuiBox>
      <SuiBox mb={2}>
        <SuiTypography variant="button" fontWeight="medium" customClass="w-max">
          Institute / Company
        </SuiTypography>
        <SuiInput
          onChange={(event) => {
            setCompany(event.target.value);
            setError({});
          }}
        />
      </SuiBox>
      <SuiBox mb={2}>
        <SuiTypography variant="button" fontWeight="medium" customClass="w-max">
          City, Country
        </SuiTypography>
        <SuiInput
          onChange={(event) => {
            setCountry(event.target.value);
            setError({});
          }}
        />
      </SuiBox>
      <SuiBox mb={2}>
        <SuiTypography variant="button" fontWeight="medium" customClass="w-max">
          Email
        </SuiTypography>
        <SuiInput
          onChange={(event) => {
            setEmail(event.target.value);
            setError({});
          }}
          type="email"
        />
      </SuiBox>
      <SuiBox>
        <Grid container spacing={1}>
          <Grid item xs={6} md={6} lg={6}>
            <SuiTypography variant="button" fontWeight="medium" customClass="w-max">
              Password
            </SuiTypography>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <SuiTypography variant="button" fontWeight="medium" customClass="w-max">
              Confirm Password
            </SuiTypography>
          </Grid>
        </Grid>
      </SuiBox>
      <SuiBox mb={2}>
        <Grid container spacing={1}>
          <Grid item xs={6} md={6} lg={6}>
            <SuiInput
              onChange={(event) => {
                setPassword(event.target.value);
                setError({});
              }}
              type={visibility ? "text" : "password"}
              withIcon={{
                icon: visibility ? <VisibilityOff /> : <Visibility />,
                direction: "right",
              }}
              handleIconClick={visibilityChange}
            />
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <SuiInput
              onChange={(event) => {
                setcnfrmPassword(event.target.value);
                setError({});
              }}
              type={confrmVisibility ? "text" : "password"}
              withIcon={{
                icon: confrmVisibility ? <VisibilityOff /> : <Visibility />,
                direction: "right",
              }}
              handleIconClick={visibilityConfrmChange}
            />
          </Grid>
        </Grid>
      </SuiBox>
      <SuiBox mb={2}>
        <SuiTypography variant="button" fontWeight="medium" customClass="w-max">
          Organization code (Optional)
        </SuiTypography>
        <SuiInput
          onChange={(event) => {
            setOrgcode(event.target.value);
            setError({});
          }}
        />
      </SuiBox>
      <SuiBox display="flex" alignItems="center">
        <Checkbox checked={agreement} onChange={handleSetAgremment} />
        <SuiTypography
          variant="button"
          fontWeight="regular"
          onClick={handleSetAgremment}
          customClass="cursor-pointer user-select-none"
        >
          I agree to&nbsp;
        </SuiTypography>
        <SuiTypography
          component="a"
          href="https://vyomasystems.com/privacy.html"
          variant="button"
          fontWeight="bold"
          textGradient
          target="_blank"
        >
          Privacy Policy,&nbsp;
        </SuiTypography>
        <SuiTypography
          component="a"
          href="https://vyomasystems.com/terms.html"
          variant="button"
          fontWeight="bold"
          textGradient
          target="_blank"
        >
          Terms & Conditions
        </SuiTypography>
      </SuiBox>
      <SuiBox mt={2} mb={2} textAlign="center">
        <h6
          style={{
            fontSize: ".8em",
            color: error.color,
            textAlign: "center",
            fontWeight: 400,
            transition: ".2s all",
          }}
        >
          {error.message}
        </h6>
      </SuiBox>
      <SuiBox mt={4} mb={1}>
        <SuiButton onClick={register} variant="gradient" buttonColor="dark" fullWidth>
          {buttonText}
        </SuiButton>
      </SuiBox>
      <SuiBox mt={3} textAlign="center">
        <SuiTypography variant="button" textColor="text" fontWeight="regular">
          Already have an account?&nbsp;
          <SuiTypography
            component={Link}
            to="/authentication/sign-in"
            variant="button"
            textColor="dark"
            fontWeight="bold"
            textGradient
          >
            Sign in
          </SuiTypography>
        </SuiTypography>
      </SuiBox>
    </CoverLayout>
  );
}

export default SignUp;
